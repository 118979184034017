import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { BsPlus } from "react-icons/bs";
import { useAuthContext } from "../context/AuthContext";
import useFetchInitialData from "../hooks/useFetchInitialData";
import { update_santa_api } from "../api/santaApi";
import { s3UploadApi, s3RemoveApi } from "../api/aws/s3";

const AddEditSecondDocModal = ({ states, setStates }) => {
  return (
    <AnimatePresence>
      {states.addSecondDocModal && (
        <SecondDocDetails
          handleClose={() =>
            setStates((prev) => ({ ...prev, addSecondDocModal: false }))
          }
        />
      )}
    </AnimatePresence>
  );
};

export default AddEditSecondDocModal;

function SecondDocDetails({ handleClose }) {
  const {
    initialState: { santaId, santa, token },
  } = useAuthContext();

  const { callMethods } = useFetchInitialData();

  // Existing "secondDoc" info (if any)
  const existingSecondDoc = santa?.documentsDetails?.secondDoc || {};

  const [pageStates, setPageStates] = useState({
    // docType can be "pan", "voter", or "driving"
    docType: existingSecondDoc.docType || "pan",
    docNumber: existingSecondDoc.number || "",

    // If images already exist, keep references; else blank
    prevFrontImage: existingSecondDoc.front || "",
    prevBackImage: existingSecondDoc.back || "",
    frontImage: existingSecondDoc.front || "",
    backImage: existingSecondDoc.back || "",
    frontImageFile: null,
    backImageFile: null,

    isSaving: false,
  });

  /**
   * Check if user can proceed:
   * Must have docNumber, frontImage, backImage (either existing or newly uploaded).
   */
  const canProceed =
    pageStates.docNumber &&
    (pageStates.frontImage || pageStates.frontImageFile) &&
    (pageStates.backImage || pageStates.backImageFile);

  /**
   * Opens camera or gallery to pick an image, storing it in frontImage/backImage
   * and frontImageFile/backImageFile respectively.
   */
  const handlePickImage = (side) => {
    if (!window.pickImage) {
      window.toast("Image picking is not supported on this platform");
      return;
    }
    window.pickImage({
      onImagePickEnd: (file, base64, compressed) => {
        setPageStates((prev) => ({
          ...prev,
          [`${side}Image`]: compressed || base64,
          [`${side}ImageFile`]: file,
        }));
      },
      cameraType: "back", // or 'front' if needed
    });
  };

  /**
   * 1) Remove old images from S3 if replaced
   * 2) Upload new images to S3
   * 3) Update secondDoc in Santa via update_santa_api
   * 4) callMethods -> refetch data
   */
  const handleSaveSecondDoc = async () => {
    if (!canProceed) return;
    setPageStates((p) => ({ ...p, isSaving: true }));

    try {
      let pathsToRemoveFromS3 = [];
      let filesToUploadToS3 = [];

      let finalFrontUrl = pageStates.frontImage;
      let finalBackUrl = pageStates.backImage;

      // If user replaced the front image:
      if (pageStates.frontImageFile) {
        if (pageStates.prevFrontImage) {
          const oldFrontFilename = extractS3Filename(pageStates.prevFrontImage);
          pathsToRemoveFromS3.push(
            `santa/${santaId}/secondDoc/${oldFrontFilename}`
          );
        }
        const newFrontName = `front_${pageStates.docType}_${Date.now()}.webp`;
        filesToUploadToS3.push({
          file: pageStates.frontImageFile,
          path: `santa/${santaId}/secondDoc/${newFrontName}`,
        });
        finalFrontUrl = `https://img.ebonow.com/custom/santa/${santaId}/secondDoc/${newFrontName}`;
      }

      // If user replaced the back image:
      if (pageStates.backImageFile) {
        if (pageStates.prevBackImage) {
          const oldBackFilename = extractS3Filename(pageStates.prevBackImage);
          pathsToRemoveFromS3.push(
            `santa/${santaId}/secondDoc/${oldBackFilename}`
          );
        }
        const newBackName = `back_${pageStates.docType}_${Date.now()}.webp`;
        filesToUploadToS3.push({
          file: pageStates.backImageFile,
          path: `santa/${santaId}/secondDoc/${newBackName}`,
        });
        finalBackUrl = `https://img.ebonow.com/custom/santa/${santaId}/secondDoc/${newBackName}`;
      }

      // Remove old images if any
      if (pathsToRemoveFromS3.length > 0) {
        await s3RemoveApi(pathsToRemoveFromS3);
      }

      // Upload new images
      if (filesToUploadToS3.length > 0) {
        const status = await s3UploadApi(filesToUploadToS3);
        if (!status) {
          window.toast("Failed to upload images, please try again.");
          setPageStates((p) => ({ ...p, isSaving: false }));
          return;
        }
      }

      // Perform the update via API
      const updateObject = {
        "documentsDetails.secondDoc.docType": pageStates.docType,
        "documentsDetails.secondDoc.number": pageStates.docNumber,
        "documentsDetails.secondDoc.front": finalFrontUrl,
        "documentsDetails.secondDoc.back": finalBackUrl,
        "documentsDetails.secondDoc.status": "inReview",
      };

      const options = {
        queryObject: { _id: santaId },
        updateObject,
        projectionString: "", // or your relevant fields
        tags: ["crud", "update"],
        token,
      };

      const res = await update_santa_api({ options });
      if (res.isSuccess) {
        await callMethods({ refetchData: true });
        handleClose();
        window.toast("Document details saved successfully!");
      } else {
        window.toast("Something went wrong while saving. Please try again.");
      }
    } catch (err) {
      console.log("Second doc error:", err);
      window.toast("Error saving document details. Please try again.");
    }
    setPageStates((p) => ({ ...p, isSaving: false }));
  };

  return (
    <motion.div
      initial={{ y: "100%" }}
      animate={{ y: 0 }}
      exit={{ y: "100%" }}
      transition={{ duration: 0.3, ease: "linear" }}
      className="flex z-20 flex-col px-4 bg-white fixed top-0 p-4 right-0 left-0 bottom-0 overflow-y-auto items-start"
    >
      {/* Close icon */}
      <div className="w-full flex items-center justify-end">
        <RxCross2 onClick={handleClose} className="text-2xl cursor-pointer" />
      </div>

      {/* Title */}
      <div className="text-[1.25rem] mt-4 font-[600] w-full">
        Upload Your Second Document
      </div>
      <div className="text-sm text-gray-500 mt-1 mb-6">
        (PAN, Voter ID, or Driving Licence)
      </div>

      {/* Document type */}
      <div className="w-full mb-4">
        <label className="text-gray-600 font-[500] text-sm">
          Second Document
        </label>
        <select
          value={pageStates.docType}
          onChange={(e) =>
            setPageStates((p) => ({ ...p, docType: e.target.value }))
          }
          className="w-full text-sm font-[500] border rounded-lg bg-blue-50 border-blue-200 px-2 min-h-[2.25rem] text-[#2136d4] mt-1"
        >
          <option value="pan">PAN Card</option>
          <option value="voter">Voter ID</option>
          <option value="driving">Driving Licence</option>
        </select>

        <input
          type="text"
          value={pageStates.docNumber}
          onChange={(e) =>
            setPageStates((p) => ({ ...p, docNumber: e.target.value }))
          }
          placeholder="Enter document number"
          className="input2 mt-2 p-2 w-full placeholder:text-xs"
        />
      </div>

      {/* Front/Back Image Section */}
      <div className="flex gap-4 mt-4 overflow-scroll">
        {/* Front Image */}
        {pageStates.frontImage ? (
          <div
            onClick={() => handlePickImage("front")}
            className="rounded-lg h-[6rem] w-[6rem] overflow-hidden border border-gray-300 cursor-pointer relative"
          >
            <img
              src={pageStates.frontImage}
              alt="Front doc"
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          <div
            onClick={() => handlePickImage("front")}
            className="cursor-pointer flex flex-col items-center justify-center text-[#2136d4] text-sm font-[500] bg-blue-100 rounded-lg h-[6rem] w-[6rem] border border-blue-200"
          >
            <BsPlus className="text-2xl mb-1" />
            Front
          </div>
        )}

        {/* Back Image */}
        {pageStates.backImage ? (
          <div
            onClick={() => handlePickImage("back")}
            className="rounded-lg h-[6rem] w-[6rem] overflow-hidden border border-gray-300 cursor-pointer relative"
          >
            <img
              src={pageStates.backImage}
              alt="Back doc"
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          <div
            onClick={() => handlePickImage("back")}
            className="cursor-pointer flex flex-col items-center justify-center text-[#2136d4] text-sm font-[500] bg-blue-100 rounded-lg h-[6rem] w-[6rem] border border-blue-200"
          >
            <BsPlus className="text-2xl mb-1" />
            Back
          </div>
        )}
      </div>

      {/* Footer: Save button pinned at bottom */}
      <div className="fixed bottom-0 left-0 right-0 px-4 py-3 bg-white border-t border-gray-200">
        <div
          className={`w-full ${
            !canProceed ? "opacity-40 pointer-events-none" : ""
          }`}
          onClick={() => !pageStates.isSaving && handleSaveSecondDoc()}
        >
          <button
            className="w-full blue-gradient text-white  py-3 rounded-md text-center font-medium active:scale-95 transition-all"
            disabled={!canProceed || pageStates.isSaving}
          >
            {pageStates.isSaving ? (
              <div
                className="loader w-[1rem] h-[1rem] mx-auto"
                style={{ borderTopColor: "white" }}
              />
            ) : (
              "Save Document"
            )}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

/**
 * Utility helper to extract the filename from a full S3 URL:
 * e.g. https://img.ebonow.com/custom/santa/123/secondDoc/frontPan_12345.webp
 * returns "frontPan_12345.webp"
 */
function extractS3Filename(fullUrl = "") {
  if (!fullUrl) return "";
  return fullUrl.split("/").pop(); // last segment
}
