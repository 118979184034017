import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CommonHeader from "../../../../../assets/CommonHeader";
import SlideToAcceptBtn from "../../../../../assets/SlideToAcceptBtn";
import { handleUpdate } from "../helperFunctions";
import { FaCameraRetro, FaCheck } from "react-icons/fa";
import { TbRepeat } from "react-icons/tb";
import { s3UploadApi } from "../../../../../api/aws/s3";
import SlideToConfirmButton from "../../../../../assets/buttons/SlideToConfirmButton";

import moment from "moment-timezone";
import { useGlobalStoreContext } from "../../../../../context/GlobalStoreContext";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function PickUpComp({ parentStates, setParentStates }) {
  const [states, setStates] = useState({
    rawMaterials: [],
    enlargedImageIndex: null, // this for enlarging the images of material coming from backend
    isPhotoModal: false,
    isLoading: false,
    materialImage: "", // used for uploading picked material image
    materialImageFile: "", // same as above, but will be uploaded to server
  });

  const {
    storeStates: { fetchedLocationInfo },
  } = useGlobalStoreContext();

  useEffect(() => {
    setStates((p) => ({
      ...p,
      rawMaterials: parentStates.order?.productId?.rawMaterials,
    }));
    // console.log(states);
    // eslint-disable-next-line
  }, []);

  const isItemPickedUp = (idx) => {
    return (
      states.rawMaterials.length > 0 &&
      states.rawMaterials[idx].pickUpStatus ===
        ("picked_by_santa" || "delivering_to_santa")
    );
  };

  const handleItemSelect = (idx) => {
    setStates((prevState) => ({
      ...prevState,
      rawMaterials: prevState.rawMaterials.map((material, index) => {
        if (index === idx) {
          return {
            ...material,
            pickUpStatus: isItemPickedUp(idx)
              ? "ready_to_pick"
              : "picked_by_santa",
            picked: isItemPickedUp(idx) ? false : true,
          };
        }
        return material;
      }),
    }));
  };

  const allItemsPickedBySanta = () => {
    return states.rawMaterials.every(
      (item) => item.pickUpStatus === "picked_by_santa"
    );
  };

  const handleSubmit = async (rawMaterialsImg) => {
    try {
      setStates((p) => ({ ...p, isLoading: true }));

      await handleUpdate({
        orderId: parentStates.order._id,
        updateObject: {
          productId: {
            ...parentStates.order.productId,
            rawMaterials: states.rawMaterials,
          },
          "images.rawMaterialsImg": rawMaterialsImg,
          $push: {
            "orderServiceStatus.$[elem].serviceStatus": {
              status: "materialPicked",
              location: fetchedLocationInfo?.location || {},
              date: moment().toISOString(),
            },
          },
        },
        arrayFilters: [{ "elem.bookingStatus": "Order Confirmed" }],
        setStates: setParentStates,
      });
      setStates((p) => ({ ...p, isPhotoModal: false }));
      setParentStates((p) => ({ ...p, isPickupPage: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const proccedToPhotoModel = () => {
    setStates((p) => ({ ...p, isPhotoModal: true }));
  };

  const handleImageClick = (idx) => {
    setStates((prevState) => ({
      ...prevState,
      enlargedImageIndex: idx,
    }));
  };

  const handleCloseEnlarged = () => {
    setStates((prevState) => ({
      ...prevState,
      enlargedImageIndex: null,
    }));
  };

  const handleUpload = async (params) => {
    try {
      let materialImageFile;
      if (params) {
        materialImageFile = params.materialImageFile;
      } else {
        materialImageFile = states.materialImageFile;
      }
      setStates((p) => ({ ...p, isLoading: true }));

      // upload to s3 logic, if success then call handleSubmit
      const materialImgToUploadObj = [
        {
          file: materialImageFile,
          path: `orders/${parentStates.order._id}/rawMaterialsImg.webp`,
        },
      ];
      await s3UploadApi(materialImgToUploadObj);

      await handleSubmit(
        `https://img.ebonow.com/custom/orders/${parentStates.order._id}/rawMaterialsImg.webp`
      );
      setStates((p) => ({ ...p, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const openCamera = () => {
    window.pickImage({
      onImagePickEnd: (file) => {
        const params = {
          materialImageFile: file,
        };
        handleUpload(params);
      },
      pickFrom: "camera",
      cameraType: "back",
      allowsEditing: false,
    });
  };

  return (
    <motion.div
      initial={{ opacity: 1, x: " 100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.5 }}
      className="fixed top-0 bottom-0 overflow-scroll pb-[4rem] left-0 right-0 bg-blue-50 z-[15]"
    >
      <CommonHeader
        content="Item Details and Images"
        navFun={() => {
          setStates((p) => ({ ...p, rawMaterials: [] }));
          setParentStates((p) => ({ ...p, isPickupPage: false }));
        }}
      />
      <div className="m-2 text-[gray] flex items-center gap-2 text-[.875rem]">
        <p className="size-[.875rem] border border-black  rounded-full p-[6px] flex items-center justify-center text-[.75rem]">
          i
        </p>
        <p className="text-[gray] text-[.75rem]">
          Tap image to see full picture
        </p>
      </div>
      <div className="bg-white text-[gray] text-[.875rem] rounded-[.5rem] border  mx-2 overflow-hidden">
        <div className=" capitalize  ">
          {states.rawMaterials?.length > 0 &&
            states.rawMaterials.map((curr, idx) => {
              const item = curr.item ? curr.item : curr.customItem;

              return (
                <div
                  key={idx}
                  className={`flex justify-between gap-4 items-center border-b py-4 px-4`}
                >
                  <div
                    onClick={() => {
                      handleItemSelect(idx);
                    }}
                    className={`w-5 h-5 mr-2 rounded-[4px] flex items-center justify-center ${
                      isItemPickedUp(idx)
                        ? "bg-white border border-[#2136D4]"
                        : "border border-gray-500"
                    }`}
                  >
                    {isItemPickedUp(idx) && (
                      <FaCheck className="text-[#2136D4] text-[1rem] p-[1px] " />
                    )}
                  </div>
                  <div className="flex gap-4  items-center flex-1 pr-2">
                    <div
                      className=" flex w-[25%]"
                      onClick={() => handleImageClick(idx)}
                    >
                      <img
                        src={item?.images ? item?.images[0] : item?.image}
                        alt="material"
                        className=" rounded-[.5rem] overflow-hidden h-[2.3rem]"
                      />
                    </div>
                    <div
                      className="w-[70%]"
                      onClick={() => {
                        handleItemSelect(idx);
                      }}
                    >
                      <p className="text-black font-medium">{item?.itemName}</p>
                    </div>
                    {item?.tags?.includes("rent") && (
                      <p>
                        <TbRepeat className="text-[1rem]" />
                      </p>
                    )}
                  </div>
                  <div
                    className="flex gap-2 items-center justify-end  w-[10%]"
                    onClick={() => {
                      handleItemSelect(idx);
                    }}
                  >
                    <p className="flex items-center">
                      <span className="text-[.625rem] mr-1">x</span>
                      {curr.quantity + curr.extraQuantity}
                      {curr.packetType === "meter"
                        ? "M"
                        : curr.packetType === "liter"
                        ? "L"
                        : ""}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <AnimatePresence>
        {states.enlargedImageIndex !== null && (
          <motion.div
            key="enlarged-image"
            className="fixed top-0 bottom-0 left-0 right-0 z-[1000] flex justify-center items-center bg-black bg-opacity-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleCloseEnlarged}
          >
            <motion.img
              src={
                states.rawMaterials[states.enlargedImageIndex]?.item?.images[0]
              }
              alt="enlarged-material"
              className="max-h-[90vh] max-w-[90vw] rounded-[.5rem]"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="p-2 fixed bottom-0 bg-white  left-0 right-0 ">
        <SlideToConfirmButton
          btnText={states.isPhotoTaken ? "Submit" : "Take picture"}
          disabled={!allItemsPickedBySanta()}
          onFullSlideTextBeforeRelease={"Release to confirm"}
          funOnFullSlide={proccedToPhotoModel}
        />
      </div>

      {states.isPhotoModal && (
        <RawMaterialsPhoto
          states={states}
          setStates={setStates}
          openCamera={openCamera}
          handleUpload={handleUpload}
        />
      )}
    </motion.div>
  );
}

function RawMaterialsPhoto({ states, setStates, handleUpload, openCamera }) {
  return (
    <motion.div
      initial={{ opacity: 1, x: " 100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3 }}
      className="fixed top-0 bottom-0 left-0 right-0 bg-blue-50 z-[10]"
    >
      {states.isLoading && (
        <div className="fixed text-white top-0 gap-2 right-0 left-0 bottom-0 bg-[#000000ac] z-[9] flex items-center justify-center ">
          Uploading...{" "}
          <div
            style={{ borderTopColor: "white" }}
            className="loader w-[1rem]"
          ></div>
        </div>
      )}
      <CommonHeader
        content="Upload photo"
        navFun={() => {
          setStates((p) => ({ ...p, isPhotoModal: false }));
        }}
      />
      <div className="mx-4 mt-2">
        {states.materialImage ? (
          <div className="relative">
            <img
              src={states.materialImage}
              alt=""
              className="min-h-[200px]  rounded-[10px] border w-full"
            />
          </div>
        ) : (
          <div className="user-photo-upload-placeholder border rounded-[12px] p-4  min-h-[500px] flex items-center justify-center bg-gray-50">
            <label className="cursor-pointer flex flex-col items-center gap-2 flex-1">
              <FaCameraRetro className="text-[4rem] text-[#2136D4]" />

              <div className="text-[gray]">
                Make sure all materials/packets are visible
              </div>
            </label>
          </div>
        )}
      </div>
      {states.materialImage ? (
        <div className="m-2 fixed flex gap-2 bottom-0 left-0 right-0 ">
          <label
            className={`py-3 px-4 text-center w-full rounded-[.5rem] font-[500] text-[1.125rem]   
      border border-gray-300 bg-gray-100   `}
            onClick={openCamera}
          >
            Retake
          </label>
          <SlideToAcceptBtn
            btnText="Confirm"
            fun={handleUpload}
            disabled={!states.materialImageFile}
            isSimple={true}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
          }}
          className="m-2 fixed flex bottom-0 left-0 right-0 "
        >
          <label
            className={`py-3 px-4 text-center w-full rounded-[.5rem] font-[500] text-[1.125rem]   
            before:w-[200px] mirror-Animation blue-gradient   `}
            onClick={openCamera}
          >
            Take picture
          </label>
        </div>
      )}
    </motion.div>
  );
}
