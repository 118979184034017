import { useContext, memo, useEffect, useState } from "react";
import Error from "../../../assets/Error";
import { Link } from "react-router-dom";
import PhoneInput from "./subComponents/PhoneInput";
import Button from "./subComponents/Button";
import AuthContext from "../../../context/AuthContext";

const LoginForm = () => {
  const { number, setNumber, error, setError, otpGeneration, serverRes } =
    useContext(AuthContext);

  const [states, setStates] = useState({
    isValidNumber: false,
  });

  useEffect(() => {
    const isValidNumber = /^\d{10}$/.test(number);
    setStates((prev) => ({
      ...prev,
      isValidNumber: isValidNumber,
    }));
  }, [number]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the phone number has exactly 10 digits
    const isValidNumber = /^\d{10}$/.test(number);

    setStates((prev) => ({
      ...prev,
      isValidNumber: isValidNumber,
    }));

    if (!isValidNumber) {
      setError("Number must be 10 digits");
      return;
    }

    // Proceed with OTP generation logic
    otpGeneration({ type: "sms" });
  };

  // console.log(states.isValidNumber)
  return (
    <>
      <div className="font-medium ">
        <div className="mt-4 font-medium">
          <p>Login or Signup</p>
          <hr className="mt-1 border-t-2 border-solid border-[#2136D4] w-[70px] " />
        </div>
        <form onSubmit={handleSubmit} className="w-[100%]">
          <div className={" pt-1 pb-2 w-[100%]"}>
            <PhoneInput number={number} setNumber={setNumber} />
          </div>
          <Error setError={setError} error={error} />
          <Button
            text={"continue"}
            type={"submit"}
            auto
            isLoading={serverRes.isLoading}
            disabled={!states.isValidNumber}
          />
        </form>

        <p className="text-xs py-4 text-[#817C7C] flex flex-wrap items-center gap-1">
          By clicking on Login, I accept the
          <Link
            className="text-sm font-medium md:font-semibold text-[#2136d4]"
            onClick={() => {
              window.openUrl("https://www.ebonow.com/terms");
            }}
          >
            Terms & Conditions
          </Link>
          &
          <Link
            onClick={() => {
              window.openUrl("https://www.ebonow.com/privacy");
            }}
            className="text-sm font-medium md:font-semibold text-[#2136d4]"
          >
            Privacy Policy
          </Link>
        </p>
      </div>
    </>
  );
};

export default memo(LoginForm);
