import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import CommonHeader from "../../../../assets/CommonHeader";
import SlideToAcceptBtn from "../../../../assets/SlideToAcceptBtn";
import { FaCameraRetro, FaCheck } from "react-icons/fa";
import { s3UploadApi } from "../../../../api/aws/s3";
import SlideToConfirmButton from "../../../../assets/buttons/SlideToConfirmButton";
import { preDefinedOptions, santaApi } from "../../../../api/santaApi";
import { useAuthContext } from "../../../../context/AuthContext";
import useFetchInitialData from "../../../../hooks/useFetchInitialData";
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function ReturnMaterialsPickup({
  states: parentStates,
  setStates: setParentStates,
  updateOrder,
}) {
  const { refetchSantaDetails } = useFetchInitialData();
  const {
    initialState: { santa },
  } = useAuthContext();

  const [states, setStates] = useState({
    rawMaterials: [],
    enlargedImageIndex: null, // this for enlarging the images of material coming from backend
    isPhotoModal: false,
    isLoading: false,
    materialImage: "", // used for uploading picked material image
    materialImageFile: "", // same as above, but will be uploaded to server
  });

  useEffect(() => {
    setStates((p) => ({
      ...p,
      rawMaterials: parentStates.returnableItems,
    }));
    // console.log(states);
    // eslint-disable-next-line
  }, []);

  const isItemPickedUp = (idx) => {
    return states.rawMaterials[idx].returned;
  };

  const handleItemSelect = (idx) => {
    setStates((prevState) => ({
      ...prevState,
      rawMaterials: prevState.rawMaterials.map((material, index) => {
        if (index === idx) {
          return {
            ...material,
            returned: !material.returned,
          };
        }
        return material;
      }),
    }));
  };

  const allItemsPickedBySanta = () => {
    return states.rawMaterials.every((item) => item.returned);
  };

  const handleSubmit = async (returnedMaterialsImg) => {
    try {
      setStates((p) => ({ ...p, isLoading: true }));

      await updateOrder({
        returnStatus: "returned",
        "images.returnedMaterialsImg": returnedMaterialsImg,
        tags: parentStates.order?.tags?.filter((_) => _ !== "return pending"),
        $push: {
          materialsReturningStatus: {
            status: "returned",
            date: moment().format(),
            tags: ["santa"],
            remark: "Matrials picked by santa",
          },
        },
      });

      await santaApi(
        preDefinedOptions.updateSanta({
          ordersWithPendingReturns:
            santa?.ordersWithPendingReturns?.filter(
              (curr) => curr.order?._id !== parentStates.order._id
            ) || [],
        })
      );

      await refetchSantaDetails();

      setParentStates((p) => ({
        ...p,
        presentedPage: "returnDonePage",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const proccedToPhotoModel = () => {
    setStates((p) => ({ ...p, isPhotoModal: true }));
  };

  const handleUpload = async (params) => {
    try {
      let materialImageFile;
      if (params) {
        materialImageFile = params.materialImageFile;
      } else {
        materialImageFile = states.materialImageFile;
      }
      setStates((p) => ({ ...p, isLoading: true }));
      // upload to s3 logic, if success then call handleSubmit
      const materialImgToUploadObj = [
        {
          file: materialImageFile,
          path: `orders/${parentStates.order._id}/returnedMaterialsImg.webp`,
          // https://img.ebonow.com/custom/orders/${parentStates.order._id}/returnedMaterialsImg.webp
        },
      ];
      await s3UploadApi(materialImgToUploadObj);

      await handleSubmit(
        `https://img.ebonow.com/custom/orders/${parentStates.order._id}/returnedMaterialsImg.webp`
      );
      setStates((p) => ({ ...p, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectImage = async (e) => {
    try {
      const imageFile = e.target.files[0];
      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setStates((prevState) => ({
            ...prevState,
            materialImage: event.target.result,
            materialImageFile: imageFile,
          }));
          const params = {
            materialImageFile: imageFile,
          };
          handleUpload(params);
        };
        reader.readAsDataURL(imageFile);
      }
    } catch (error) {
      console.error("Error checking camera permission:", error);
    }
  };

  const openCamera = () => {
    window.pickImage({
      onImagePickEnd: (file) => {
        const params = {
          materialImageFile: file,
        };
        handleUpload(params);
      },
      pickFrom: "camera",
      cameraType: "back",
      allowsEditing: false,
    });
  };

  return (
    <motion.div
      initial={{ opacity: 1, x: " 100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.5 }}
      className="fixed top-0 bottom-0 overflow-scroll pb-[4rem] left-0 right-0 bg-blue-50 z-[99]"
    >
      <CommonHeader
        content="Item Details and Images"
        navFun={() => {
          setParentStates((p) => ({ ...p, presentedPage: "default" }));
        }}
      />
      <div className="m-2 text-[gray] flex items-center gap-2 text-[.875rem]">
        <p className="w-[.875rem] h-[.875rem] border border-black  rounded-full p-[6px] flex items-center justify-center text-[.75rem]">
          i
        </p>
        <p className="text-[gray] text-[.75rem]">
          Tap image to see full picture
        </p>
      </div>
      <div className="bg-white text-[gray] text-[.875rem] rounded-[.5rem] border  mx-2 overflow-hidden">
        <div className=" capitalize  ">
          {states.rawMaterials?.length > 0 &&
            states.rawMaterials.map((curr, idx) => {
              const item = curr.item ? curr.item : curr.customItem;

              return (
                <div
                  key={idx}
                  className={`flex justify-between gap-4 items-center border-b py-4 px-4`}
                >
                  <div
                    onClick={() => {
                      handleItemSelect(idx);
                    }}
                    className={`w-5 h-5 mr-1 rounded-full flex items-center justify-center ${
                      isItemPickedUp(idx)
                        ? "blue-gradient"
                        : "border border-gray-500"
                    }`}
                  >
                    {isItemPickedUp(idx) && (
                      <FaCheck className=" text-[8px]  " />
                    )}
                  </div>
                  <div className="flex gap-4  items-center flex-1 pr-2">
                    <img
                      src={item?.images ? item?.images[0] : item?.image}
                      alt="material"
                      onClick={() => {
                        window.showMsg(
                          item?.images ? item?.images[0] : item?.image,
                          "img"
                        );
                      }}
                      className=" aspect-square w-[3rem] rounded-lg border border-gray-200"
                    />

                    <div
                      style={{
                        width: "calc(100% - 3rem)",
                      }}
                      onClick={() => {
                        handleItemSelect(idx);
                      }}
                    >
                      <p className="text-black font-medium">{item?.itemName}</p>
                    </div>
                  </div>
                  <div
                    className="flex gap-2 items-center justify-end  w-[10%]"
                    onClick={() => {
                      handleItemSelect(idx);
                    }}
                  >
                    <p className="flex items-center">
                      <span className="text-[.625rem] mr-1">x</span>
                      {curr.quantity + curr.extraQuantity}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="p-2 fixed bottom-0 bg-white  left-0 right-0 ">
        <SlideToConfirmButton
          btnText={"Take materials picture"}
          disabled={!allItemsPickedBySanta()}
          onFullSlideTextBeforeRelease={"Release to confirm"}
          funOnFullSlide={proccedToPhotoModel}
        />
      </div>

      {states.isPhotoModal && (
        <RawMaterialsPhoto
          states={states}
          setStates={setStates}
          handleSelectImage={handleSelectImage}
          openCamera={openCamera}
          handleUpload={handleUpload}
        />
      )}
    </motion.div>
  );
}

function RawMaterialsPhoto({ states, setStates, handleUpload, openCamera }) {
  return (
    <motion.div
      initial={{ opacity: 1, x: " 100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3 }}
      className="fixed top-0 bottom-0 left-0 right-0 bg-blue-50 z-[10]"
    >
      {states.isLoading && (
        <div className="fixed text-white top-0 gap-2 right-0 left-0 bottom-0 bg-[#000000ac] z-[9] flex items-center justify-center ">
          Uploading...{" "}
          <div
            style={{ borderTopColor: "white" }}
            className="loader w-[1rem]"
          ></div>
        </div>
      )}
      <CommonHeader
        content="Upload materials photo"
        navFun={() => {
          setStates((p) => ({ ...p, isPhotoModal: false }));
        }}
      />
      <div className="mx-4 mt-2">
        {states.materialImage ? (
          <div className="relative">
            <img
              src={states.materialImage}
              alt=""
              className="min-h-[200px]  rounded-[10px] border w-full"
            />
          </div>
        ) : (
          <div className="user-photo-upload-placeholder border rounded-[12px] p-4  min-h-[500px] flex items-center justify-center bg-gray-50">
            <label className="cursor-pointer flex flex-col items-center gap-2 flex-1">
              <FaCameraRetro className="text-[4rem] text-[#2136D4]" />

              <div className="text-[gray]">
                Make sure all materials/packets are visible
              </div>
            </label>
          </div>
        )}
      </div>
      {states.materialImage ? (
        <div className="m-2 fixed flex gap-2 bottom-0 left-0 right-0 ">
          <label
            className={`py-3 px-4 text-center w-full rounded-[.5rem] font-[500] text-[1.125rem]   
      border border-gray-300 bg-gray-100   `}
            onClick={openCamera}
          >
            Retake
          </label>

          <SlideToAcceptBtn
            btnText="Confirm"
            fun={handleUpload}
            disabled={!states.materialImageFile}
            isSimple={true}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
          }}
          className="m-2 fixed flex bottom-0 left-0 right-0 "
        >
          <label
            className={`py-3 px-4 text-center w-full rounded-[.5rem] font-[500] text-[1.125rem]   
            before:w-[200px] mirror-Animation blue-gradient   `}
            onClick={openCamera}
          >
            Take picture
          </label>
        </div>
      )}
    </motion.div>
  );
}
