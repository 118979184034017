import { useContext } from "react";
import LoginForm from "./LoginForm";
import AuthContext from "../../../context/AuthContext";
import OTPform from "./OTPform";
import Header from "./Header";

export default function AuthPageIndex() {
  const {
    authPage: { loginPage, otpPage },
  } = useContext(AuthContext);

  return (
    <div className="flex fixed top-0 right-0 bg-[white] left-0 bottom-0  flex-col z-[999]">
      <Header />
      <div className="mx-6">
        {loginPage && <LoginForm />}
        {otpPage && <OTPform />}
      </div>
    </div>
  );
}
