import { handleMsgFromNative } from "./handleMsgFromNative";

export default function communicateBetweenReactAndNative(
  parameters,
  storeStates,
  setStoreStates,
  compRenderStates
) {
  try {
    let options = parameters;

    if (typeof parameters === "string") {
      // means we passing a stringified JSON
      options = JSON.parse(parameters);
    }

    const {
      msgFrom = "react", // native | react
      operation = "",
      content = {},
    } = options;

    if (msgFrom === "react") {
      // send the content to the native as stringified JSON
      window.ReactNativeWebView?.postMessage(JSON.stringify(options));
    } else {
      handleMsgFromNative({
        operation,
        content,
        storeStates,
        setStoreStates,
        compRenderStates,
      });
    }
  } catch (error) {
    console.error(error);
  }
}
