import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { useGlobalStoreContext } from "../../../context/GlobalStoreContext";
import IOSLocationEnableGuideModal from "../../reusable/warnNotifiesComp/location/IOSLocationEnableGuideModal";
import AndroidLocationEnableGuideModal from "../../reusable/warnNotifiesComp/location/AndroidLocationEnableGuideModal";

// window.locationRequiredModal(true);
const LocationRequireModal = () => {
  const {
    storeStates: {
      santaAppCredentials: { device },
      fetchedLocationInfo: { gpsOn, foregroundPermission, location },
    },
  } = useGlobalStoreContext();

  const [states, setStates] = useState({
    isLocationGuideModalRender: false,
  });

  // if we got the location permission or location then just close the modal
  useEffect(() => {
    if (
      gpsOn &&
      foregroundPermission === "granted" &&
      location?.lat

      // || device === "web"
    ) {
      window.floatingModal(false);
      setStates({ ...states, isLocationGuideModalRender: false });
    }

    // eslint-disable-next-line
  }, [foregroundPermission, gpsOn, location, device]);

  const openSettings = async () => {
    // ask for the location permission

    if (foregroundPermission === "denied" || foregroundPermission === "") {
      window.communicateToNative("openSettings");
    } else if (!gpsOn) {
      window.communicateToNative("fetchLocation");
      window.toast("Please enable the GPS to get the location");
    } else {
      window.communicateToNative("fetchLocation");
    }

    setStates({ ...states, isLocationGuideModalRender: true });
  };

  return (
    <div className="flex flex-col w-[95vw]  flex-shrink-0 justify-center rounded-lg shadow-sm border border-gray-200 items-center p-8 bg-white">
      {states.isLocationGuideModalRender && (
        <>
          {device === "ios" ? (
            <IOSLocationEnableGuideModal
              onClose={() => {
                setStates({ ...states, isLocationGuideModalRender: false });
              }}
            />
          ) : (
            <AndroidLocationEnableGuideModal
              onClose={() => {
                setStates({ ...states, isLocationGuideModalRender: false });
              }}
            />
          )}
        </>
      )}
      <div className="mb-5">
        <FaLocationDot size={80} color="#2136d4" />
      </div>
      <h1 className="text-2xl font-bold text-[#2136d4] ">Location Required</h1>
      <div className="my-3 space-y-2">
        <p className="text-gray-600">
          • Allow location to get <strong>nearby orders</strong>
        </p>
        <p className="text-gray-600">
          • To view <strong>order location</strong> and{" "}
          <strong>get direction</strong>
        </p>
      </div>

      <button
        className="blue-gradient text-white font-bold py-3 mt-2 px-8 rounded"
        onClick={openSettings}
      >
        Enable Location
      </button>
    </div>
  );
};

export default LocationRequireModal;
