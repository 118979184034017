import React from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";

export default function IOSLocationEnableGuideModal({ onClose }) {
  return (
    <div
      onClick={() => {
        window.communicateToNative("fetchLocation");
      }}
      className="z-[40] bg-[#0000009e] fix-screen flex-center"
    >
      {/* Main modal container */}
      <div className="w-[95vw] max-w-md rounded-lg p-4 bg-white border border-gray-200">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">Enable Location (iOS)</h2>
          {/* Optional close icon
          <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
            &times;
          </button> */}
        </div>

        {/* Steps / Guide */}
        <div className="space-y-4">
          <p className="text-gray-700">
            To get nearby orders, please enable location:
          </p>

          {/* Step 1: Go to iOS Settings */}
          <div className="flex items-start space-x-3">
            <div className="text-blue-500 flex-shrink-0 mt-1">
              <AiOutlineSetting size={24} />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">Open Settings</h3>
              <p className="text-gray-600 text-sm">
                Tap on the <strong>Settings</strong> app on your Home Screen.
                Then navigate to <strong>Privacy &amp; Security</strong> →{" "}
                <strong>Location Services</strong>.
              </p>
            </div>
          </div>

          {/* Step 2: Enable Location Services */}
          <div className="flex items-start space-x-3">
            <div className="text-green-500 flex-shrink-0 mt-1">
              <MdLocationOn size={24} />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">
                Enable Location Services
              </h3>
              <p className="text-gray-600 text-sm">
                Make sure <strong>Location Services</strong> is toggled on.
                Scroll down to find this app in the list and select
                <strong> While Using the App</strong> or <strong>Always</strong>{" "}
                for location access.
              </p>
            </div>
          </div>
        </div>

        {/* Action Button */}
        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          >
            Ok, Got It!
          </button>
        </div>
      </div>
    </div>
  );
}
