import React, { createContext, useState, useContext } from "react";

const CompRenderingContext = createContext();

// creating a hook
export const useCompRendering = () => useContext(CompRenderingContext);

export const CompRenderingProvider = ({ children }) => {
  const [onboardingSuccessModal, setOnboardingSuccessModal] = useState({
    isRender: false,
    type: "",
  });

  const [successMsg, setSuccessMsg] = useState({
    msg: "",
    isRender: false,
  });

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    isRender: false,
  });

  const [states, setStates] = useState({
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
    toast1: {
      isRender: false,
      text: "",
      options: {
        customZIndex: 1000,
      },
    },
    DateRangeSelector1: {
      isRender: false,
      type: "",
    },
    FloatingCustomModal: {
      isRender: false,
      component: null,
      UIOptions: {},
      options: {},
    },
    askForInputs: {
      isRender: false,
      labels: [],
      heading: "",
      subHeading: "",
      onSubmitCallback: null,
      saveBtnTxt: "Done",
    },
    askForRadio: {
      isRender: false,
      labels: [],
      heading: "",
      subHeading: "",
      onSubmitCallback: null,
      saveBtnTxt: "Done",
      isOtherOptionAvailable: false,
    },
    LoadingScreen1: {
      isRender: false,
      msg: "",
    },
    AskImagePickFrom: {
      isRender: false,
      msg: "",
      imagePickInitiated: false,
      result: null,
    },
    addBankDetailsModal: false,
    addPanDetailsModal: false,
    CallToUserModal: {
      isRender: false,
      user: [],
      onErrorCallBack: () => {},
    },
  });

  return (
    <CompRenderingContext.Provider
      value={{
        onboardingSuccessModal,
        setOnboardingSuccessModal,
        successMsg,
        setSuccessMsg,
        toastMsg,
        setToastMsg,
        states,
        setStates,
      }}
    >
      {children}
    </CompRenderingContext.Provider>
  );
};

export default CompRenderingContext;
