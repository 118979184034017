// src/components/fallbackPages/update/UpdateBoundary.js
import { useEffect, useState } from "react";
import { useGlobalStoreContext } from "../../../context/GlobalStoreContext";
import { useAuthContext } from "../../../context/AuthContext";

const UpdateBoundary = ({ children, fallback }) => {
  const {
    storeStates: { santaAppCredentials }, // santaAppCredentials : {currAppVersion , installedAppVersion ...}
  } = useGlobalStoreContext();

  const { initialState } = useAuthContext();

  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  useEffect(() => {
    // version structuring to compare easily | ex:- 1.0.0 => 100
    const currAppVersion =
      santaAppCredentials?.currAppVersion?.split(".")?.join("") || 0;
    const installedAppVersion =
      santaAppCredentials?.installedAppVersion?.split(".")?.join("") || 0;

    // if (
    //   (currAppVersion &&
    //     installedAppVersion &&
    //     parseInt(currAppVersion) > parseInt(installedAppVersion)) ||
    //   (santaAppCredentials?.installedAppVersion === null &&
    //     initialState?.santa?._id)
    // ) {
    //   setIsUpdateAvailable(true);
    // } else {
    //   setIsUpdateAvailable(false);
    // }

    // eslint-disable-next-line
  }, [santaAppCredentials]);

  if (isUpdateAvailable) {
    return fallback;
  }

  return children;
};

export default UpdateBoundary;
