import moment from "moment";
import { getDistance } from "geolib";

export default function orderUtils() {
  const getTodaysOrders = (santa) => {
    const acceptedOrderRequests = santa.acceptedOrderRequests
      ?.filter((curr) => {
        return moment(curr.order?.date?.delivery?.celebrationDate).isSame(
          moment(),
          "day"
        );
      })
      ?.map((curr) => curr.order);

    return { acceptedOrderRequests };
  };

  const getOptedIndOngoingOrder = (santa) => {
    try {
      const todaysOrders = getTodaysOrders(santa)?.acceptedOrderRequests;
      const outForServiceOrder = todaysOrders?.find((curr) => {
        const isCurrSantaOptedOut = curr?.santaGroups?.santas
          ?.find((_) => _.santa?._id === santa._id)
          ?.tags?.includes("optout");

        return (
          !isCurrSantaOptedOut && curr?.bookingStatus === "Out for Service"
        );
      });

      if (!outForServiceOrder) {
        return {
          order: null,
          isOngoing: false,
          isOnTheWay: false,
          todaysOrders,
          radialDistanceOfOrderFromSantaLastLocation: null,
        };
      }

      const isServiceStarted = getOrderServiceStatusDetails({
        rootStatus: "serviceStarted",
        order: outForServiceOrder,
      })?.isAvailable;

      const isServiceCompleted = getOrderServiceStatusDetails({
        rootStatus: "complete",
        order: outForServiceOrder,
      })?.isAvailable;

      const isSantaArrived = getOrderServiceStatusDetails({
        rootStatus: "santaArrived",
        order: outForServiceOrder,
      })?.isAvailable;

      const radialDistanceOfOrderFromSantaLastLocation = santa.lastLogin
        ?.location
        ? getRadialDistanceOfOrderFromPoint({
            order: outForServiceOrder,
            point: santa.lastLogin?.location,
          })
        : null;

      return {
        order: outForServiceOrder,
        isOngoing: isServiceStarted && !isServiceCompleted,
        isOnTheWay: !isSantaArrived,
        radialDistanceOfOrderFromSantaLastLocation,
        todaysOrders,
      };
    } catch (error) {
      window.throwError(
        error,
        { funName: "getOptedIndOngoingOrder" },
        "orderUtils.js"
      );
    }
  };

  const getOrderServiceStatusDetails = ({ rootStatus, order }) => {
    try {
      let isAvailable = false;
      let matchedServiceStatus = null;
      let matchedBookingStatus = null;
      let matchedServiceStatusDate = null;
      let matchedStatusIndexes = []; // [bookingIndex, serviceIndex]

      if (!order?.orderServiceStatus) {
        return {
          isAvailable,
          matchedServiceStatus,
          matchedBookingStatus,
          matchedServiceStatusDate,
          matchedStatusIndexes,
        };
      }

      order.orderServiceStatus.forEach((booking) => {
        booking.serviceStatus.forEach((service) => {
          if (service.status === rootStatus) {
            isAvailable = true;
            matchedServiceStatus = service;
            matchedBookingStatus = booking.bookingStatus;
            matchedServiceStatusDate = moment(service.date);
            matchedStatusIndexes = [
              order.orderServiceStatus.indexOf(booking),
              booking.serviceStatus.indexOf(service),
            ];
          }
        });
      });

      return {
        isAvailable,
        matchedServiceStatus,
        matchedBookingStatus,
        matchedServiceStatusDate,
        matchedStatusIndexes,
      };
    } catch (error) {
      window.throwError(
        error,
        { funName: "getOrderServiceStatusDetails" },
        "orderUtils.js"
      );
    }
  };

  const getRadialDistanceOfOrderFromPoint = ({ order, point }) => {
    try {
      const orderPoint = order.addressId?.locationv2;
      const distance = getDistance?.(
        { latitude: point.lat, longitude: point.lng },
        { latitude: orderPoint.lat, longitude: orderPoint.lng }
      );

      return distance;
    } catch (error) {
      window.throwError(
        error,
        { funName: "getRadialDistanceOfOrderFromPoint" },
        "orderUtils.js"
      );
    }
  };

  const lastServiceStatus = (order) => {
    const lastServiceStatus = order.orderServiceStatus.reduce((acc, curr) => {
      const lastService = curr.serviceStatus[curr.serviceStatus.length - 1];
      if (lastService) {
        if (!acc.date || moment(acc.date).isBefore(moment(lastService.date))) {
          return lastService;
        }
      }
      return acc;
    }, {});

    return lastServiceStatus;
  };

  return {
    getTodaysOrders,
    getOptedIndOngoingOrder,
    getOrderServiceStatusDetails,
    lastServiceStatus,
    getRadialDistanceOfOrderFromPoint,
  };
}
