import { base64ToFile } from "../helperFn";

export const handleMsgFromNative = ({
  operation,
  content,
  storeStates,
  setStoreStates,
  compRenderStates,
}) => {
  switch (operation) {
    case "setAppVersion":
      setStoreStates((p) => ({
        ...p,
        santaAppCredentials: {
          ...p.santaAppCredentials,
          installedAppVersion: content,
        },
      }));
      break;

    case "toast":
      window.toast(content);
      break;

    case "locationFetched":
      setStoreStates((p) => ({
        ...p,
        fetchedLocationInfo: {
          ...p.fetchedLocationInfo,
          isAskedForLocationPermission: true,
          ...content,
        },
      }));
      break;

    case "imagePickerResult":
      handleImagePickerInfoFromNative(content, compRenderStates);
      break;
    case "webSourceFrom":
      setStoreStates((p) => ({
        ...p,
        webSourceFrom: content,
      }));
      break;
    case "setFCMToken":
      setStoreStates((p) => ({
        ...p,
        santaAppCredentials: {
          ...p.santaAppCredentials,
          FCMToken: content,
        },
      }));
      break;
    case "notificationStatus":
      setStoreStates((p) => ({
        ...p,
        santaAppCredentials: {
          ...p.santaAppCredentials,
          isAskedForNotificationPermission: true,
          notificationStatus: content,
        },
      }));
      break;
    case "onFCMMessage":
      window.showMsg(content, "json");
      break;

    case "setDeviceType":
      setStoreStates((p) => ({
        ...p,
        santaAppCredentials: {
          ...p.santaAppCredentials,
          device: content,
        },
      }));
      break;

    default:
      break;
  }
};

const handleImagePickerInfoFromNative = async (content, compRenderStates) => {
  try {
    const base64Url = content?.base64;
    const isCancelled = content.canceled;
    const imagePickFromData = content?.data;

    if (isCancelled) {
      compRenderStates?.AskImagePickFrom?.onImagePicked({
        isCancelled,
      });
      return;
    }

    const file = base64ToFile(base64Url, "pickedImage.jpg", "image/jpeg");

    compRenderStates?.AskImagePickFrom?.onImagePicked({
      file,
      imagePickFromData,
      base64Url,
      isCancelled,
    });
  } catch (error) {
    window.toast(error.message, "error");
  }
};
