import axios from "axios";
const Base_Url = process.env.REACT_APP_BACKEND_URL;

const getOrderForSantaApi = async ({
  orderId,
  projectionString,
  santaLocation,
}) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      console.log("No token found");
      return;
    }
    if (!orderId || !projectionString) {
      console.log("Order Id or projection is missing");
      return;
    }
    const res = await axios.post(`${Base_Url}/santa`, {
      options: {
        token: token,
        tags: ["order", "orderDetailGet"],
        orderId,
        projectionString,
        santaLocation,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const sendPaymentLinkSmsToCustomer = async (variables) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      console.log("No token found");
      return;
    }

    const res = await axios.post(`${Base_Url}/santa`, {
      options: {
        token,
        tags: ["sms"],
        type: "paymentDueReminder",
        variables,
        phoneNumber: variables.phoneNumber,
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

const updateOrderForSantaApi = async ({
  queryObject,
  projectionString = "_id",
  updateObject,
  arrayFilters = null,
  tags = [],
  santaLocation,
}) => {
  window.consoleToNative({ santaLocation, tags });

  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      console.log("No token found");
      return;
    }
    if (!queryObject) {
      console.log("parameters are missing");
      return;
    }
    const res = await axios.post(`${Base_Url}/santa`, {
      options: {
        token: token,
        tags: tags
          ? [...tags, "order", "updateOrderDetail"]
          : ["order", "updateOrderDetail"],
        queryObject,
        updateObject,
        projectionString,
        arrayFilters,
        santaLocation,
      },
    });
    return res.data;
  } catch (error) {
    window.throwError(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const verifyOtpForOrderApi = async ({ orderId, otp }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      console.log("No token found");
      return;
    }
    if (!orderId || !otp) {
      console.log("Order Id or otp is missing");
      return;
    }
    const res = await axios.post(`${Base_Url}/santa`, {
      options: {
        token: token,
        tags: ["order", "verifyOrderOtp"],
        orderId,
        otp,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const resendOtpForOrderApi = async ({ orderId }) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (!token) {
      console.log("No token found");
      return;
    }
    if (!orderId) {
      console.log("Order Id is missing");
      return;
    }
    const res = await axios.post(`${Base_Url}/santa`, {
      options: {
        token: token,
        tags: ["order", "resendOrderOtp"],
        orderId,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

export {
  getOrderForSantaApi,
  updateOrderForSantaApi,
  verifyOtpForOrderApi,
  resendOtpForOrderApi,
  sendPaymentLinkSmsToCustomer,
};
