import { useContext, useState } from "react";
import { motion } from "framer-motion";
import { TbChevronCompactDown, TbChevronCompactUp } from "react-icons/tb";
import { haversineDistance } from "../../orderDetails/subC/helperFunctions";
import { MdCall } from "react-icons/md";
import MapEmbed from "../../../../assets/location/MapEmbed";
import GlobalStoreContext from "../../../../context/GlobalStoreContext";
import SlideToConfirmButton from "../../../../assets/buttons/SlideToConfirmButton";
import { SANTA_HELP_NUMBER } from "../../../../utils/constants";
import { BiChevronLeft } from "react-icons/bi";
import moment from "moment-timezone";
import AddressIdx from "../../orderDetails/liveOrder/subC/address/AddressIdx";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function DirectionPage({
  states: parentStates,
  setStates: setParentStates,
  address,
  MIN_DISTANCE_FOR_ARRIVE_INVALIDATION,
  updateOrder,
}) {
  const { storeStates } = useContext(GlobalStoreContext);

  const [states, setStates] = useState({
    btnDisabled: false,
    isExpanded: true,
    santaTeamSelfie: true,
    isOtpVerifyPage: true,
    isLoading: false,
    santaGroupImg: "",
    santaGroupImgFile: "",
    isOtpVerified: false,
    isVerificationStarted: false,
  });

  const handleLocationCheck = () => {
    if (MIN_DISTANCE_FOR_ARRIVE_INVALIDATION > 2000) {
      handleSubmit(null, "arrived");
    } else if (!parentStates.loadingCurrentLocation) {
      if (storeStates?.fetchedLocationInfo?.location?.lat) {
        const isWithin100Meters = haversineDistance(
          storeStates?.fetchedLocationInfo?.location?.lat,
          storeStates?.fetchedLocationInfo?.location?.lng,
          address.locationv2.lat,
          address.locationv2.lng,
          MIN_DISTANCE_FOR_ARRIVE_INVALIDATION
        );

        if (isWithin100Meters) {
          handleSubmit();
        } else {
          window.warningModal({
            msg: " You are not at the service location. Please visit the location first and try again.",
            lb_text: "Skip",
            rb_text: "Ok",
            lb_fn: () => {
              window.askRadio(
                "Please provide a reason for not arrived",
                "",
                [
                  "We are arrived at the location. Still not able to proceed",
                  "Technical issue occuring at the location",
                ],
                "Done",
                (r, i) => {
                  handleSubmit(r);
                  console.log(r, i);
                },
                true
              );
            },
            rb_fn: () => {},
          });
        }
      } else {
        window.warningModal({
          msg: "Getting issue while fetching your location. Please check your location settings and try again.",
          lb_text: "Skip",
          rb_text: "Ok",
          lb_fn: () => {
            window.askRadio(
              "Please provide a reason for not arrived",
              "",
              [
                "We are arrived at the location. Still not able to proceed",
                "Technical issue occuring at the location",
              ],
              "Done",
              (r, i) => {
                handleSubmit(r);
                console.log(r, i);
              },
              true
            );
          },
          rb_fn: () => {},
        });
      }
    }
  };

  const handleSubmit = async (skipRemark) => {
    try {
      setStates((p) => ({ ...p, isLoading: true }));
      let updateObject = {
        returnStatus: "atLocation",
        $push: {
          materialsReturningStatus: {
            status: "atLocation",
            date: moment().format(),
            tags: skipRemark ? ["santa", "byPassed"] : ["santa"],
            remark: skipRemark
              ? `Bypassed: ${skipRemark}`
              : "Santa arrived at the location",
          },
        },
      };

      setParentStates((p) => ({
        ...p,
        presentedPage: "materialsCheckoutPage",
      }));

      await updateOrder(updateObject);

      setStates((p) => ({ ...p, isLoading: false }));
    } catch (error) {
      setStates((p) => ({ ...p, isLoading: false }));
      console.log(error);
    }
  };

  const isSameNumber =
    parseInt(parentStates?.order?.userId?.phoneNumber) ===
    parseInt(parentStates?.order?.addressId?.receiversDetails?.mobileNumber);

  return (
    <>
      <motion.div
        initial={{ opacity: 1, x: " 100%" }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.2 }}
        className="fixed top-0 bottom-0  left-0 right-0 bg-blue-50 z-[15]"
      >
        {/* header */}
        <div className="fixed top-0 right-0 left-0 h-[3.5rem] border-b rounded-b-xl py-3 px-2 flex items-center gap-2 text-[1rem]  blue-gradient z-10 ">
          <div
            onClick={() => {
              setParentStates((p) => ({ ...p, presentedPage: "default" }));
            }}
            className="flex w-full items-center text-[1rem] relative"
          >
            <BiChevronLeft className="text-[1.5rem] " />
            <p className=" text-lg font-[600] ">Serviced Direction</p>
          </div>
        </div>

        <div
          height={window.innerHeight}
          width={window.innerWidth}
          style={{
            bottom: states.isExpanded ? "19rem" : "6.5rem",
          }}
          className=" bg-gray-300 pointer-events-none transition-all fixed top-[4rem] right-0 left-0 z-[-1] overflow-hidden flex items-center justify-center"
        >
          <MapEmbed
            height={window.innerHeight + 200}
            width={window.innerWidth}
            lat={address.locationv2.lat}
            lng={address.locationv2.lng}
          />
        </div>
        <div className="fixed bottom-0 bg-[white] left-0 right-0 border-t rounded-[20px]  ">
          <motion.div
            initial={{ height: 0 }}
            animate={{
              height: states.isExpanded
                ? isSameNumber
                  ? "18rem"
                  : "22rem"
                : "2.5rem",
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="relative z-[2] rounded-t-xl  bg-white"
          >
            <div
              className=" flex justify-center relative items-center mt-1 mb-4"
              onClick={() => {
                setStates((p) => ({ ...p, isExpanded: !p.isExpanded }));
              }}
            >
              {states.isExpanded ? (
                <TbChevronCompactDown className="text-[1.5rem]" />
              ) : (
                <TbChevronCompactUp className="text-[1.5rem]" />
              )}
            </div>

            <AddressIdx
              order={parentStates.order}
              updateOrder={updateOrder}
              isContainer={false}
            />

            <div className="w-full flex mb-6 py-4 items-center justify-between px-4 bg-white gap-2">
              <div
                onClick={() => {
                  window.openUrl(`tel:${SANTA_HELP_NUMBER}`);
                }}
                className="flex items-center text-[#2136d4] border border-[#2136d4] bg-blue-50 rounded-lg  w-full  text-sm font-[600] py-3 justify-center gap-2"
              >
                <MdCall className="text-base mb-[3px] " />
                Contact ebo support
              </div>
            </div>
          </motion.div>
          <div className="p-2 z-[4] relative bg-white">
            <SlideToConfirmButton
              btnText={"Arrived to destination"}
              disabled={false}
              funOnFullSlide={handleLocationCheck}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
}
