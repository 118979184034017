import {
  getOrderForSantaApi,
  updateOrderForSantaApi,
} from "../../../../api/orderApi";

import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export const projectionString =
  "date _id santaGroups productId images addressId QRScanner feedbacks decorWorkPictures disableCallMasking imagesInstructionForSanta santaInstructions priceDetail orderWallet santaGroups rawMaterials tags addOns freebie bookingStatus  orderServiceStatus orderOtp";

const onInitialLoad = async ({
  santaId,
  nav,
  orderId,
  states,
  setStates,
  location,
  locationFetchedTime,
}) => {
  try {
    // handle order fetch
    setStates((p) => ({ ...p, isLoadingOrder: true }));
    const res = await getOrderForSantaApi({
      orderId,
      santaLocation: {
        lat: location.lat,
        lng: location.lng,
        locationFetchedTime: locationFetchedTime,
      },
      projectionString: projectionString,
    });
    if (res.isSuccess) {
      const orderValidations = handleOrderValidations(res.order);
      let amt = 0;
      const adjustmentsArr = res?.order?.orderWallet || [];
      const santaHandlingFee = res.order?.santaGroups?.santaHandlingFee;
      const santaServiceCosting = res.order?.santaGroups?.santaServiceCosting;

      amt += santaServiceCosting + santaHandlingFee;
      for (const adj of adjustmentsArr) {
        if (adj.inOutType === "in") {
          amt += adj.amount;
        } else {
          amt -= adj.amount;
        }
      }
      setStates((p) => ({ ...p, finalAmount: amt }));
      // checking is the order is completed and santa feedback is given
      if (
        res.order.bookingStatus === "Order Completed" &&
        res.order?.feedbacks?.santa?.stars > 0
      ) {
        nav("/");
      }

      setStates((p) => ({
        ...p,
        ...orderValidations,
        isLoadingOrder: false,
        order: res.order,
        finalAmount: amt,
        santaIndexInSantaGroup: res.order.santaGroups.santas.findIndex(
          (santa) => santa?.santa?._id.toString() === santaId.toString()
        ),
        button: {},
      }));
    } else {
      setStates((p) => ({ ...p, isLoadingOrder: false }));
    }
  } catch (error) {
    console.log(error);
  }
};

const handleOrderValidations = (order) => {
  let sheduledDate = moment(order.date.delivery.celebrationDate).hours(
    order.date.delivery.readyTime
  );
  let isPastOrder = moment().subtract(1, "dates").isAfter(sheduledDate, "date");
  let todaysOrder = moment().isSame(sheduledDate, "date");
  let isCompleted = order.bookingStatus === "Order Completed";
  return { todaysOrder, isPastOrder, isCompleted };
};

const handleUpdate = async ({
  orderId,
  updateObject,
  arrayFilters,
  setStates,
  isOrderCompletionProcess,
  tags,
  santaLocation,
}) => {
  try {
    setStates((p) => ({ ...p, isLoading: true }));

    const res = await updateOrderForSantaApi({
      queryObject: { _id: orderId },
      updateObject: updateObject,
      arrayFilters: arrayFilters,
      projectionString: projectionString,
      santaLocation,
      tags: isOrderCompletionProcess ? ["orderComplete"] : tags ? tags : [],
    });

    if (res.isSuccess) {
      setStates((p) => ({
        ...p,
        order: res.order,
      }));
    } else {
      setStates((p) => ({ ...p, isLoading: false }));
    }
  } catch (error) {
    console.log(error);
  }
};

const getBtnInfo = ({
  order,
  setStates,
  states,
  bookingStatus,
  currServiceStatus,
}) => {
  let txt = "Proceed";
  let subTxt = "";
  let fn = () => {};
  let disabled = false;
  let subText = "";
  if (bookingStatus === "Order Confirmed") {
    if (currServiceStatus === "materialPacked") {
      txt = "Start material pickup";
      fn = () => {
        setStates((p) => ({ ...p, isPickupPage: true }));
      };
    } else if (currServiceStatus === "materialPicked") {
      if (
        !moment(order.date.delivery.celebrationDate)
          .startOf("day")
          .hours(order.date.delivery.arrivingTime)
          .isBefore(moment().add(6, "hours"))
      ) {
        txt = "Out for Service";
        fn = () => {};
        disabled = true;
        subText = "Will be active before 6 hr of arrival time";
      } else {
        txt = "Out for Service";
        fn = () => {
          handleUpdate({
            orderId: order._id,
            updateObject: {
              // bookingStatus: "Out for Service",
              // $push: {
              //   orderServiceStatus: {
              //     bookingStatus: "Out for Service",
              //     serviceStatus: [
              //       { status: "onTheWay", date: moment().toISOString() },
              //     ],
              //   },
              // },
            },
            setStates: setStates,
            tags: ["statusToOutForService"],
          });
          setStates((p) => ({ ...p, isCurrStatusPageRender: true }));
        };
      }
    } else {
      txt = "Start material pickup";
      subText = "Will be available soon";
      disabled = true;
    }
  } else if (bookingStatus === "Out for Service") {
    if (
      ["onTheWay", "santaArrived", "otpVerified"].includes(currServiceStatus)
    ) {
      if (currServiceStatus === "onTheWay") txt = "View direction";
      if (currServiceStatus === "santaArrived") txt = "Verify OTP";
      if (currServiceStatus === "otpVerified") txt = "Take selfie";
      fn = () => {
        setStates((p) => ({ ...p, isCurrStatusPageRender: true }));
      };
    } else if (currServiceStatus === "santaSelfiTaken") {
      txt = "Start Service"; // currently this will auto triggered upon uploading selfie

      fn = () => {
        handleUpdate({
          orderId: order._id,
          updateObject: {
            $push: {
              "orderServiceStatus.$[elem].serviceStatus": {
                status: "serviceStarted",
                date: moment().toISOString(),
              },
            },
          },
          arrayFilters: [{ "elem.bookingStatus": "Out for Service" }],
          setStates: setStates,
        });
      };
    } else if (currServiceStatus === "serviceStarted") {
      txt = "Complete Service";
    } else if (currServiceStatus === "serviceEnd") {
      txt = "Leave Location";
    } else if (currServiceStatus === "santaLeaved") {
    } else {
    }
  } else {
  }

  return {
    btnTxt: txt,
    fun: fn,
    subBtnTxt: subTxt,
    disabled: disabled,
    subText,
  };
};

const getLatestStatus = ({ bookingStatus, orderServiceStatus }) => {
  let latestStatus;
  if (bookingStatus === "Order Confirmed") {
    const orderConfirmedStatus = orderServiceStatus.find(
      (item) => item.bookingStatus === "Order Confirmed"
    );
    if (orderConfirmedStatus) {
      latestStatus =
        orderConfirmedStatus.serviceStatus.length > 0
          ? orderConfirmedStatus.serviceStatus[
              orderConfirmedStatus.serviceStatus.length - 1
            ].status
          : null;
    }
  } else if (bookingStatus === "Out for Service") {
    const outForServiceStatus = orderServiceStatus.find(
      (item) => item.bookingStatus === "Out for Service"
    );
    if (outForServiceStatus) {
      latestStatus =
        outForServiceStatus.serviceStatus.length > 0
          ? outForServiceStatus.serviceStatus[
              outForServiceStatus.serviceStatus.length - 1
            ].status
          : null;
    }
  }
  return latestStatus;
};

export function haversineDistance(
  lat1,
  lon1,
  lat2,
  lon2,
  MIN_DISTANCE_FOR_ARRIVE_INVALIDATION
) {
  if (MIN_DISTANCE_FOR_ARRIVE_INVALIDATION > 2000) {
    return true;
  }
  // Radius of the Earth in meters
  const R = 6371000.0;

  // Convert latitude and longitude from degrees to radians
  const radLat1 = (lat1 * Math.PI) / 180;
  const radLon1 = (lon1 * Math.PI) / 180;
  const radLat2 = (lat2 * Math.PI) / 180;
  const radLon2 = (lon2 * Math.PI) / 180;

  // Calculate differences in coordinates
  const dLat = radLat2 - radLat1;
  const dLon = radLon2 - radLon1;

  // Haversine formula to calculate the distance
  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in meters
  const distance = R * c;

  return distance < MIN_DISTANCE_FOR_ARRIVE_INVALIDATION;
}

export const findServiceStatus = (orderServiceStatus, serviceStatus) => {
  let foundParent = null;
  let foundChild = null;

  orderServiceStatus.forEach((parent) => {
    const child = parent.serviceStatus.find(
      (status) => status.status === serviceStatus
    );
    if (child) {
      foundParent = parent;
      foundChild = child;
    }
  });

  return foundParent ? { parent: foundParent, child: foundChild } : null;
};

export { onInitialLoad, handleUpdate, getBtnInfo, getLatestStatus };
