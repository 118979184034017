import { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { batchedSantaApi, preDefinedOptions } from "../api/santaApi";
import { useGlobalStoreContext } from "../context/GlobalStoreContext";

const useFetchInitialData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { initialState, setInitialState } = useContext(AuthContext);

  const { setStoreStates } = useGlobalStoreContext();

  const [searchQueries] = useSearchParams();
  const navigate = useNavigate();

  async function handleTokenLoginForRootAdminAccess() {
    const token = searchQueries.get("token");
    const santaId = searchQueries.get("santaId");

    if (!token || !santaId) {
      return;
    }

    localStorage.setItem("ACCESS_TOKEN", token);
    localStorage.setItem("SANTA_ID", santaId);

    window.location.href = "/";
  }

  async function callMethods(props = {}) {
    // will make the pages invisible to santa and a spinner will be shown
    setIsLoading(true);

    handleTokenLoginForRootAdminAccess();

    const santaId = localStorage.getItem("SANTA_ID");
    const token = localStorage.getItem("ACCESS_TOKEN");

    if (!props.refetchData && santaId && initialState?.santa?._id === santaId) {
      // if the santa is already fetched
      // then no need to fetch again
      setIsLoading(false);
      return;
    }

    if (!santaId || !token) {
      // the santa must be new to open the app
      // navigate him to login page

      navigate("/login");
      setIsLoading(false);
      return;
    }

    const res = await fetchSantaAppInitialData();

    if (!res?.santa) {
      // if the santa is not found
      // navigate him to login page

      navigate("/login");
      setIsLoading(false);
      return;
    }

    if (!res?.santa?.profileDraftDetails?.isOnboardingComplete) {
      // onboarding for the santa is not completed yet
      // navigate him to onboarding page

      navigate("/onboarding");
      setIsLoading(false);
      return;
    }

    if (res.santa) {
      // if the santa is onboarded
      // navigate him to home page
      if (
        window.location.pathname === "/login" ||
        window.location.pathname.includes("/onboarding")
      ) {
        navigate("/");
      }
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  }

  const fetchSantaAppInitialData = async () => {
    // if santa is onboarded
    try {
      const res = await batchedSantaApi([
        // get santa details
        preDefinedOptions?.santaDetailsGet(),
        preDefinedOptions?.getWeeklyEarnings(),
        {
          operation: "getInitialDataFromDataHub",
        },
      ]);

      const getInitialDataFromDataHub = res[2]?.send?.data;

      const serviceableCities = getInitialDataFromDataHub?.find(
        (d) => d.type === "serviceableCities"
      )?.data;
      const santaAppCredentials = getInitialDataFromDataHub?.find(
        (d) => d.type === "santaCredentials"
      )?.data;

      if (!res || res.length === 0) {
        window.toast("Something went wrong", "error");
        return {};
      }

      let santa = null;

      // extracting the santa details from the response
      const santaDetailsRes = res[0]?.send;

      const weeklyEarningsRes = res[1]?.send;
      if (santaDetailsRes?.isSuccess) {
        santa = santaDetailsRes?.santa;
        setInitialState((p) => ({
          ...p,
          santa,
          isLoading: false, // will close the skeleton
        }));
      } else {
        window.toast("Something went wrong", "error");
      }

      // setting the weekly earnings
      if (weeklyEarningsRes && weeklyEarningsRes?.length > 0) {
        setStoreStates((p) => ({
          ...p,
          santaEarningsData: {
            ...p.santaEarningsData,
            weeklyEarnings: weeklyEarningsRes,
          },
          serviceableCities,
          santaAppCredentials: {
            ...p.santaAppCredentials,
            ...santaAppCredentials,
          },
        }));
      }

      return { santa };
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const refetchSantaDetails = async () => {
    try {
      const res = await batchedSantaApi([
        // get santa details
        preDefinedOptions?.santaDetailsGet(),
      ]);

      if (!res || res.length === 0) {
        window.toast("Something went wrong", "error");
        return {};
      }

      let santa = null;

      // extracting the santa details from the response
      const santaDetailsRes = res[0]?.send;

      if (santaDetailsRes?.isSuccess) {
        santa = santaDetailsRes?.santa;
        setInitialState((p) => ({
          ...p,
          santa,
          isLoading: false, // will close the skeleton
        }));
      } else {
        window.toast("Something went wrong", "error");
      }

      return { santa };
    } catch (error) {
      console.error(error);
    }
  };

  return { isLoading, callMethods, refetchSantaDetails };
};

export default useFetchInitialData;
