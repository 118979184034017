import { createContext, memo, useContext, useState } from "react";
import { get_santa_details_api, update_santa_api } from "../api/santaApi";
import AuthContext from "./AuthContext";
import { useGlobalStoreContext } from "./GlobalStoreContext";

const OnboardingContext = createContext();

export const OnboardingProvider = memo(({ children }) => {
  const { initialState } = useContext(AuthContext);

  const {
    storeStates: { fetchedLocationInfo },
  } = useGlobalStoreContext();
  const [onboardingState, setOnboardingState] = useState({
    currentPage: 0, // for 1-for personal, 2-for work, 3-for document
    pageUnlocked: 1,
    pageCompleted: 0,
    isOnboardingCompleted: false,
    onboardingStatus: "inComplete",
  });

  const fetchSantaOnboarding = async () => {
    const options = {
      queryObject: {
        _id: initialState.santaId,
      },
      projectionString:
        "personalDetails notification _id address workArea  workDetails bankDetails documentsDetails profileDraftDetails",
      tags: ["crud", "get_santa_details"],
      token: initialState.token,
    };
    const { santa } = await get_santa_details_api({ options: options });
    setOnboardingState((prevState) => ({
      ...prevState,
      ...santa,
      pageCompleted: santa?.profileDraftDetails?.pageCompleted || 0,
      isOnboardingCompleted:
        santa?.profileDraftDetails?.isOnboardingComplete || false,
      pageUnlocked: santa?.profileDraftDetails
        ? santa.profileDraftDetails.pageCompleted + 1
        : 1,
      onboardingStatus: santa?.profileDraftDetails?.onboardingStatus,
    }));
  };

  const handleDraftSave = async ({ updateObject }) => {
    try {
      if (fetchedLocationInfo.location.lat) {
        updateObject["lastLogin.date"] =
          fetchedLocationInfo.locationFetchedTime;
        updateObject["lastLogin.location"] = fetchedLocationInfo.location;
      }

      const options = {
        queryObject: { _id: initialState.santaId },
        updateObject,
        projectionString:
          "personalDetails address workArea workDetails documentsDetails profileDraftDetails",
        tags: ["crud", "update"],
        token: initialState.token,
      };
      const res = await update_santa_api({ options });

      return { isSuccess: true, data: res.data };
    } catch (error) {
      return {
        isSuccess: false,
        message: error.message,
      };
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingState,
        setOnboardingState,
        handleDraftSave,
        fetchSantaOnboarding,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
});

export default OnboardingContext;
