import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { s3UploadApi } from "../../../../../../../api/aws/s3";
import { handleUpdate } from "../../../../subC/helperFunctions";

export default function MaterialAddModal({
  setParentStates,
  order,
  states,
  setStates,
}) {
  return (
    <AnimatePresence>
      {states.materialAddModal && (
        <Modal
          order={order}
          setParentStates={setParentStates}
          states={states}
          setStates={setStates}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ setParentStates, states, setStates, order }) => {
  const [modalStates, setModalStates] = useState({
    isLoading: false,
    itemName: "",
    price: "", // per peice
    img: "",
    imgFile: "",
    quantity: "",
    validToAdd: false,
  });

  useEffect(() => {
    if (
      modalStates.itemName &&
      modalStates.price &&
      modalStates.imgFile &&
      modalStates.quantity
    ) {
      if (!modalStates.validToAdd) {
        setModalStates((p) => ({ ...p, validToAdd: true }));
      }
    } else {
      if (modalStates.validToAdd) {
        setModalStates((p) => ({ ...p, validToAdd: false }));
      }
    }
  }, [modalStates]);

  const handleClose = () =>
    setStates((p) => ({ ...p, materialAddModal: false }));

  const openCamera = () => {
    window.pickImage({
      onImagePickEnd: (file, b64, img) => {
        setModalStates((prevState) => ({
          ...prevState,
          img: img,
          imgFile: file,
        }));
      },
      pickFrom: "choice",
      cameraType: "back",
      allowsEditing: false,
    });
  };

  const handleUpload = async () => {
    try {
      if (
        !modalStates.itemName ||
        !modalStates.price ||
        !modalStates.imgFile ||
        !modalStates.quantity
      ) {
        window.toast("Please provide all the material information first");
        return;
      }
      setModalStates((p) => ({ ...p, isLoading: true }));
      const materialCount = order.productId.rawMaterials.length;
      // upload to s3 logic, if success then call handleSubmit
      const imgToUploadObj = [
        {
          file: modalStates.imgFile,
          path: `orders/${order._id}/materials/material${materialCount}.webp`,
        },
      ];
      await s3UploadApi(imgToUploadObj);

      const imgUrl = `https://img.ebonow.com/custom/orders/${order._id}/materials/material${materialCount}.webp`;

      await handleUpdate({
        orderId: order._id,
        updateObject: {
          $push: {
            "productId.rawMaterials": {
              item: "",
              customItem: {
                itemName: modalStates.itemName,
                price: modalStates.price, // per peice
                image: imgUrl,
              },
              quantity: parseInt(modalStates.quantity),
              extraQuantity: 0,
              extraPriceForRawMaterial: 0,
              tags: ["added by santa"],
            },
          },
        },
        arrayFilters: [],
        setStates: setParentStates,
      });
      handleClose();
      setModalStates((p) => ({ ...p, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" fixed top-0 left-0 bottom-0 right-0 z-[99] bg-[#0000006d] ">
      <motion.div
        initial={{ y: "42rem" }}
        animate={{ y: 0 }}
        exit={{ y: "42rem" }}
        transition={{ duration: 0.3 }}
        className="fixed bottom-0 left-0 flex flex-col h-[39rem] rounded-t-xl text-[.875rem] items-center right-0 bg-[white] z-[1]"
      >
        <div className=" absolute top-4 left-4 ">
          <RxCross2
            onClick={handleClose}
            className="text-[1.5rem] text-[black]"
          />
        </div>
        <div
          onClick={() => {
            window.toast("Something went wrong", "error");
          }}
          className="mt-[3rem] text-[1.125rem] w-full px-4 font-[500]"
        >
          <span className="text-[#2136d4] "></span>Material details
        </div>

        <div className="w-full px-4 text-[gray] leading-4 text-[.875rem]">
          Provide the material details you have purchased
        </div>
        <div className="w-full px-4 flex flex-col mt-[2rem] ">
          <div className=" text-[gray] leading-4 text-[.875rem]">
            Material name
          </div>
          <input
            type="text"
            value={modalStates.itemName}
            onChange={(e) =>
              setModalStates((p) => ({
                ...p,

                itemName: e.target.value,
              }))
            }
            placeholder="Name"
            className="input2 w-full py-2 mt-1 px-2"
          />
        </div>
        <div className="w-full px-4 flex flex-col mt-[1rem] ">
          <div className=" text-[gray] leading-4 text-[.875rem]">
            Material price (per piece)
          </div>
          <input
            type="number"
            inputMode="numeric"
            value={modalStates.price}
            onChange={(e) =>
              setModalStates((p) => ({
                ...p,
                price: e.target.value,
              }))
            }
            placeholder="Price per piece"
            className="input2 w-full py-2 mt-1 px-2"
          />
        </div>
        <div className="w-full px-4 flex flex-col mt-[1rem] ">
          <div className=" text-[gray] leading-4 text-[.875rem]">
            Material quantity
          </div>
          <input
            type="number"
            inputMode="numeric"
            value={modalStates.quantity}
            onChange={(e) =>
              setModalStates((p) => ({
                ...p,
                quantity: e.target.value,
              }))
            }
            placeholder="Each piece count"
            className="input2 w-full py-2 mt-1 px-2"
          />
        </div>
        <div className="w-full px-4 h-[10rem] flex flex-col mt-[1.5rem] ">
          {modalStates.img ? (
            <div className="relative flex gap-2 items-center">
              <img
                src={modalStates.img}
                alt=""
                className=" w-[8rem]  rounded-[10px] "
              />
              <div className="user-photo-upload-placeholder border rounded-[12px] px-8 p-2 flex items-center justify-center bg-blue-50 border-blue-200 text-[#2136d4]">
                <label
                  onClick={openCamera}
                  className="cursor-pointer flex flex-col items-center gap-2 flex-1"
                >
                  Retake
                </label>
              </div>
            </div>
          ) : (
            <div className="user-photo-upload-placeholder border rounded-[12px] p-3 flex items-center justify-center bg-blue-50 border-blue-200 text-[#2136d4]">
              <label
                onClick={openCamera}
                className="cursor-pointer flex flex-col items-center gap-2 flex-1"
              >
                Take material picture
              </label>
            </div>
          )}
        </div>

        <div
          onClick={() => {
            !modalStates.isLoading && handleUpload();
          }}
          style={{ opacity: modalStates.validToAdd ? 1 : 0.6 }}
          className=" w-[95%] h-[3.5rem] py-3 px-[5rem] gap-1 mt-[3rem] mirror-Animation before:w-[20px] font-[500] blue-gradient flex items-center rounded-lg justify-center text-[1.125rem] "
        >
          {modalStates.isLoading ? (
            <div
              style={{ borderTopColor: "white" }}
              className="loader w-[1.25rem]"
            ></div>
          ) : (
            "Add Material"
          )}
        </div>
      </motion.div>
    </div>
  );
};
