import React from "react";
import "./index.css";
import "./App.css";
import { GlobalStoreProvider } from "./context/GlobalStoreContext";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { OnboardingProvider } from "./context/OnboardingContext";
import { CompRenderingProvider } from "./context/CompRenderingContext";
import MainRouter from "./routes/MainRouter";
import ErrorBoundary from "./components/fallbackPages/error/ErrorBoundary";
import ErrorPage from "./components/fallbackPages/error/ErrorPage";
import UpdateBoundary from "./components/fallbackPages/update/UpdateBoundary";
import UpdatePage from "./components/fallbackPages/update/UpdatePage";
import WindowBoundary from "./boundaries/WindowBoundary";

function App() {
  return (
    <Router>
      <CompRenderingProvider>
        <GlobalStoreProvider>
          <WindowBoundary>
            <AuthProvider>
              <OnboardingProvider>
                <ErrorBoundary fallback={<ErrorPage />}>
                  <UpdateBoundary fallback={<UpdatePage />}>
                    <MainRouter />
                  </UpdateBoundary>
                </ErrorBoundary>
              </OnboardingProvider>
            </AuthProvider>
          </WindowBoundary>
        </GlobalStoreProvider>
      </CompRenderingProvider>
    </Router>
  );
}

export default App;
