import React, { createContext, useContext, useEffect, useState } from "react";
import { useCompRendering } from "./CompRenderingContext";
import communicateBetweenReactAndNative from "../utils/communicateBtwReactAndNative/communicateBetweenReactAndNative";

const GlobalStoreContext = createContext();
export const useGlobalStoreContext = () => useContext(GlobalStoreContext);

export const GlobalStoreProvider = ({ children }) => {
  const { states: compRenderStates } = useCompRendering();

  const [storeStates, setStoreStates] = useState({
    isGoogleMapsScriptLoaded: false,
    santaEarningsData: {
      // will be present on the initial load of the app.
      // it will contain the current week and last 3 weeks data on the first load.
      weeklyEarnings: [
        // {
        //   week: 32,
        //   ...data
        // },
      ],
    },
    serviceableCities: {},
    santaAppCredentials: {
      device: "web", // android , ios, web
      installedAppVersion: null,
      FCMToken: null,
      isAskedForNotificationPermission: false,
      notificationStatus: "unavailable", // granted, denied, unavailable
    },
    fetchedLocationInfo: {
      lastLocationUpdateToDb: null,
      isAskedForLocationPermission: false,
      foregroundPermission: "", // location foreground permission || granted, denied, prompt, checking, ""
      backgroundPermission: "", // location background permission || granted, denied, prompt, checking, ""
      gpsOn: false,
      location: { lat: null, lng: null },
      locationFetchedTime: null,
    },
  });

  // this is the state for the santa team details
  const [santaTeam, setSantaTeam] = useState({
    team: [],
  });

  useEffect(() => {
    // send a message to native that the react site has mounted
    communicateBetweenReactAndNative(
      {
        operation: "reactSiteMounted",
        msgFrom: "react",
        content: "",
      },
      storeStates,
      setStoreStates,
      compRenderStates
    );

    // eslint-disable-next-line
  }, []);

  return (
    <GlobalStoreContext.Provider
      value={{
        handleVibrate: () => {
          communicateBetweenReactAndNative({
            operation: "vibration",
            impactLevel: "light",
            storeStates,
            setStoreStates,
          });
        },
        storeStates,
        setStoreStates,
        santaTeam,
        setSantaTeam,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  );
};

export default GlobalStoreContext;
