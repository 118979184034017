import React, { useContext, useEffect, useRef, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import usePullToRefresh, {
  RefreshLoader,
} from "../../../hooks/usePullToRefresh";
import { FaLocationArrow } from "react-icons/fa6";
import AuthContext from "../../../context/AuthContext";
import { santaApi } from "../../../api/santaApi";
import moment from "moment-timezone";
import { FaCheckCircle } from "react-icons/fa";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function CompletedOrdersList() {
  const {
    initialState: { santaId, token },
  } = useContext(AuthContext);

  const containerRef = useRef(null);

  const [localStates, setLocalStates] = useState({
    sortedOrders: [],
    sortedGroups: [],
    workedOrders: [],
    dateFrom: moment().startOf("isoWeek"),
    dateTo: moment().endOf("isoWeek"),
    isLoading: false,
  });

  useEffect(() => {
    const groupedOrders = localStates?.workedOrders
      ?.filter((curr) => curr.orderId)
      ?.reduce((acc, curr) => {
        const celebrationDate = moment(
          curr.orderId?.date?.delivery?.celebrationDate
        ).format("YYYY-MM-DD");
        if (!acc[celebrationDate]) {
          acc[celebrationDate] = [];
        }
        acc[celebrationDate].push(curr);
        return acc;
      }, {});

    const sortedGroups = Object.keys(groupedOrders)
      .sort((a, b) => (moment(a).isAfter(moment(b)) ? -1 : 1))
      .map((date) => {
        const orders = groupedOrders[date].sort(
          (a, b) =>
            parseInt(b.orderId?.date?.delivery?.arrivingTime) -
            parseInt(a.orderId?.date?.delivery?.arrivingTime)
        );
        return { date, orders };
      });

    const filteredGroups = sortedGroups.filter((group) => {
      const groupDate = moment(group.date, "YYYY-MM-DD");
      return groupDate.isBetween(
        localStates.dateFrom,
        localStates.dateTo,
        null,
        "[]"
      );
    });

    const sortedOrders = filteredGroups.flatMap((group) => group.orders);

    setLocalStates((p) => ({
      ...p,
      sortedOrders,
      sortedGroups: filteredGroups,
    }));

    // eslint-disable-next-line
  }, [localStates.workedOrders, localStates.dateFrom]);

  const [refereshStates, setRefereshStates] = useState({});

  useEffect(() => {
    handleOrderReferesh();

    // eslint-disable-next-line
  }, []);

  const handleOrderReferesh = async () => {
    try {
      setLocalStates((p) => ({ ...p, isLoading: true }));
      const tags = ["getSantaWorkedOrders"];

      const options = {
        santaId,
        tags: tags,
        token: token,
      };
      const res = await santaApi(options);

      if (res.isSuccess) {
        setLocalStates((p) => ({ ...p, workedOrders: res.orders }));
      } else {
        window.toast("Something went wrong", "error");
      }
      setLocalStates((p) => ({ ...p, isLoading: false }));
    } catch (error) {
      console.error(error);
    }
  };

  usePullToRefresh(
    containerRef,
    () => {
      handleOrderReferesh();
    },
    (params) => {
      setRefereshStates(params);
    },
    {
      isGradient: false,
      loaderColor: "#2136d4",
    }
  );

  const handlePrevWeek = () => {
    const prevStartDate = moment(localStates.dateFrom)
      .subtract(7, "days")
      .startOf("isoweek");
    const prevEndDate = moment(prevStartDate).endOf("isoweek");
    setLocalStates((prevStates) => ({
      ...prevStates,
      dateFrom: prevStartDate,
      dateTo: prevEndDate,
    }));
  };

  const handleNextWeek = () => {
    const nextStartDate = moment(localStates.dateFrom)
      .add(7, "days")
      .startOf("isoweek");
    const nextEndDate = moment(nextStartDate).endOf("isoweek");
    setLocalStates((prevStates) => ({
      ...prevStates,
      dateFrom: nextStartDate,
      dateTo: nextEndDate,
    }));
  };

  const isFirstWorkingWeek = moment(localStates.dateFrom).isBefore(
    "2024-04-02"
  );

  const isCurrentWeek = moment().isSameOrBefore(
    localStates.dateFrom,
    "isoweek"
  );

  if (localStates.isLoading) {
    return (
      <div className="w-[95%] mt-4 gap-2 flex flex-col ">
        <div className=" ebo_skeleton h-[5.5rem] w-full rounded-lg "></div>
        <div className=" ebo_skeleton h-[5.5rem] w-full rounded-lg "></div>
        <div className=" ebo_skeleton h-[5.5rem] w-full rounded-lg "></div>
      </div>
    );
  }

  return (
    <>
      <div
        ref={containerRef}
        className="w-[95%] pb-[8rem] overflow-x-hidden overflow-y-scroll scroll-bar-remove h-full gap-2 flex flex-col items-start justify-start "
      >
        <RefreshLoader refereshStates={refereshStates} />

        <div className="flex flex-col items-center justify-center w-full mt-3">
          <div className="flex items-center gap-4 ">
            <div
              className={`text-[1.4rem] bg-black bg-opacity-30 text-white rounded-full ${
                isFirstWorkingWeek && "opacity-20"
              }`}
              onClick={() => !isFirstWorkingWeek && handlePrevWeek()}
            >
              <BiChevronLeft />
            </div>
            <p>{`${moment(localStates.dateFrom).format("Do MMM")} - ${moment(
              localStates.dateTo
            ).format("Do MMM")}`}</p>
            <div
              className={`text-[1.4rem] bg-black bg-opacity-30 text-white rounded-full ${
                isCurrentWeek && "opacity-20"
              }`}
              onClick={() => {
                !isCurrentWeek && handleNextWeek();
              }}
            >
              <BiChevronRight />
            </div>
          </div>
        </div>

        {localStates.sortedGroups.length === 0 && (
          <div className=" h-full w-full text-[grey] ttext-[1rem] flex flex-col items-center justify-center  ">
            No Completed orders
          </div>
        )}
        {localStates.sortedGroups.map((currDate, currDateIdx) => {
          return (
            <div key={currDateIdx} className="flex flex-col gap-2 w-full">
              <div className="text-[1rem] mt-4 font-[500] pl-2">
                {formatDateTime(
                  moment(currDate.date, "YYYY-MM-DD").toISOString()
                )}
              </div>
              {currDate.orders.map((currOrder, currOrderIdx) => {
                return (
                  <CompletedOrderCard
                    order={currOrder.orderId}
                    currOrderIdx={currOrderIdx}
                    currDateIdx={currDateIdx}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
}

const CompletedOrderCard = ({ order, currOrderIdx, currDateIdx }) => {
  const nav = useNavigate();
  const delivery = order?.date?.delivery;
  const address = order?.addressId;

  return (
    <div
      onClick={() => nav("/orders/completed/" + order?._id)}
      key={currOrderIdx + "_" + currDateIdx}
      className={` bg-white relative flex-col w-full    border rounded-lg  border-gray-200 pt-4 pb-4   `}
    >
      <div className="flex items-center w-full justify-between px-4 ">
        <div
          style={{ width: "calc(100% - 2rem)" }}
          className="flex items-center w-full gap-3"
        >
          <img
            src={order.productId?.images[0]}
            alt=""
            className="w-[3rem] rounded-full border border-gray-200 aspect-square"
          />
          <div
            style={{
              width: "calc(100% - 4rem)",
            }}
            className="flex flex-col"
          >
            <div className="text-[black] text-sm font-[600]  ">
              <span className="text-gray-500 font-medium">Arrival by:</span>
              {moment(delivery.arrivingTime, "HH").format(" hh:mm A")}
            </div>
            <div className="flex gap-1 text-sm items-center">
              <FaLocationArrow className="text-[#2136d4] text-[1rem] " />
              <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                {address?.locationv2?.addressLine2}
              </div>
            </div>
          </div>
        </div>
        <FaCheckCircle className=" text-xl text-green-600 " />
      </div>
    </div>
  );
};

const formatDateTime = (timeOfPresence) => {
  const now = moment();
  const presenceDate = moment(timeOfPresence);

  if (presenceDate.isSame(now, "day")) {
    return "Today";
  } else if (presenceDate.isSame(now.clone().add(1, "day"), "day")) {
    return "Tomorrow";
  } else if (presenceDate.isSame(now.clone().subtract(1, "day"), "day")) {
    return "Yesterday";
  } else {
    return presenceDate.format("Do MMMM ");
  }
};

export const findServiceStatus = (order, serviceStatus) => {
  let foundParent = null;
  let foundChild = null;

  order?.orderServiceStatus?.forEach((parent) => {
    const child = parent.serviceStatus.find(
      (status) => status.status === serviceStatus
    );
    if (child) {
      foundParent = parent;
      foundChild = child;
    }
  });

  return foundParent ? { parent: foundParent, child: foundChild } : null;
};
