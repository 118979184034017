import React from "react";
import { MdLocationOn, MdSettings } from "react-icons/md";

export default function AndroidLocationEnableGuideModal({
  onClose = () => {},
}) {
  return (
    <div
      onClick={() => {
        window.communicateToNative("fetchLocation");
      }}
      className="z-[40] bg-[#0000009e] fix-screen flex-center"
    >
      {/* The main modal container */}
      <div className="w-[95vw] max-w-md rounded-lg p-4 bg-white border border-gray-200">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">Enable Location</h2>
          {/* If you want a close "X" icon: 
            <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
              &times;
            </button>
          */}
        </div>

        {/* Instructions */}
        <div className="space-y-4">
          <p className="text-gray-700">
            To get nearby orders, please enable your device's location access:
          </p>

          {/* Step 1: Quick settings approach */}
          <div className="flex items-start space-x-3">
            <div className="text-blue-500 flex-shrink-0 mt-1">
              <MdLocationOn size={24} />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">Quick Settings</h3>
              <p className="text-gray-600 text-sm">
                Swipe down from the top of your screen to open Quick Settings.
                Tap the <strong>Location</strong> or <strong>GPS</strong> icon
                to enable it.
              </p>
            </div>
          </div>

          {/* Step 2: Full settings approach */}
          <div className="flex items-start space-x-3">
            <div className="text-green-500 flex-shrink-0 mt-1">
              <MdSettings size={24} />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">Device Settings</h3>
              <p className="text-gray-600 text-sm">
                Open your <strong>Settings</strong> app, then look for{" "}
                <strong>Location</strong> or{" "}
                <strong>Security & Location</strong>. Toggle{" "}
                <strong>Location</strong> on.
              </p>
            </div>
          </div>
        </div>

        {/* Action button */}
        <div className="mt-6 flex justify-end w-full">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded w-full blue-gradient text-white  transition-colors"
          >
            Ok, Got It!
          </button>
        </div>
      </div>
    </div>
  );
}
